import { Flex, Layout, Menu, Spin } from 'antd';
import ErrorBoundary from 'antd/es/alert/ErrorBoundary';
import { Suspense, useEffect, useState } from 'react';
import { AiOutlineCustomerService } from 'react-icons/ai';
import { BsBoxSeam, BsTicketPerforated } from 'react-icons/bs';
import { FaRegBell } from 'react-icons/fa';
import { FiLayout } from 'react-icons/fi';
import { LuUsers } from 'react-icons/lu';
import { PiStorefrontBold } from 'react-icons/pi';
import { RiEBike2Line } from 'react-icons/ri';

import { TbNotes } from 'react-icons/tb';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Outlet, useNavigate } from 'react-router-dom';
import css from './Dashboard.module.scss';
import Logo from './assets/images/logo.png';
import Header from './components/Header';
import { PERMISSIONS } from './constants/constants';
import { useLoginStatus } from './hooks/useLoginStatus';
import { setDeliveryAlerts } from './redux/features/delivery/deliverySlice';
import { setOrderAlerts } from './redux/features/orders/orderSlice';
const Dashboard = () => {
  const { Sider } = Layout;
  const navigate = useNavigate();
  const [orderWorkerInstance, setOrderWorkerInstance] = useState(null);
  const [deliveryWorkerInstance, setDeliveryWorkerInstance] = useState(null);
  const dispatch = useDispatch();
  const { user } = useLoginStatus();
  const orderAlerts = useSelector(state => state.order.orderAlerts);
  const deliveryAlerts = useSelector(state => state.delivery.deliveryAlerts);

  useEffect(() => {
    if (!orderWorkerInstance) {
      const orderWorker = new Worker(
        new URL('./workers/orderWorker.js', import.meta.url),
        {
          type: 'module',
        },
      );
      setOrderWorkerInstance(orderWorker);
      orderWorker.onmessage = event => {
        dispatch(setOrderAlerts(event.data));
      };

      orderWorker.postMessage({
        token: localStorage.getItem('token'),
      });
    }

    if (!deliveryWorkerInstance) {
      const deliveryWorker = new Worker(
        new URL('./workers/deliveryWorker.js', import.meta.url),
        {
          type: 'module',
        },
      );
      setDeliveryWorkerInstance(deliveryWorker);
      deliveryWorker.onmessage = event => {
        dispatch(setDeliveryAlerts(event.data));
      };

      deliveryWorker.postMessage({
        token: localStorage.getItem('token'),
      });
    }

    return () => {
      if (orderWorkerInstance) {
        orderWorkerInstance.terminate();
      }
      if (deliveryWorkerInstance) {
        deliveryWorkerInstance.terminate();
      }
    };
  }, []);

  const items = [
    {
      key: '1',
      label: 'Dashboard',
      onClick: () => navigate('/'),
      icon: <FiLayout size={20} />,
      permissionName: [
        PERMISSIONS.COUPONS,
        PERMISSIONS.DELIVERY,
        PERMISSIONS.ORDERS,
        PERMISSIONS.RESTAURANTS,
        PERMISSIONS.CUSTOMER,
        PERMISSIONS.SUPPORT,
        PERMISSIONS.NUTRITIONIST,
      ],
    },
    {
      key: '2',
      label: 'Orders',
      icon: <BsBoxSeam size={20} />,
      onClick: () => navigate('/orders'),
      permissionName: [PERMISSIONS.ORDERS],
      alerts: orderAlerts?.page?.totalAlerts,
    },
    {
      key: '3',
      label: 'Restaurants',
      icon: <PiStorefrontBold size={20} />,
      onClick: () => navigate('/restaurants'),
      permissionName: [PERMISSIONS.RESTAURANTS],
    },
    {
      key: '4',
      label: 'Nutritionists',
      icon: <TbNotes size={20} />,
      onClick: () => navigate('/nutritionists'),
      permissionName: [PERMISSIONS.NUTRITIONIST],
    },
    {
      key: '5',
      label: 'Customers',
      icon: <LuUsers size={20} />,
      onClick: () => navigate('/customers'),
      permissionName: [PERMISSIONS.CUSTOMER],
    },
    {
      key: '6',
      label: 'Coupons',
      icon: <BsTicketPerforated size={20} />,
      onClick: () => navigate('/coupons'),
      permissionName: [PERMISSIONS.COUPONS],
    },
    {
      key: '7',
      label: 'Support',
      icon: <AiOutlineCustomerService size={20} />,
      onClick: () => navigate('/support'),
      permissionName: [PERMISSIONS.SUPPORT],
    },
    {
      key: '8',
      label: 'Delivery',
      icon: <RiEBike2Line size={20} />,
      onClick: () => navigate('/delivery'),
      permissionName: [PERMISSIONS.DELIVERY],
      alerts: deliveryAlerts?.page?.totalAlerts,
    },
    {
      key: '9',
      label: 'App Notification',
      icon: <FaRegBell size={20} />,
      onClick: () => navigate('/app-notification'),
      permissionName: [PERMISSIONS.ADMIN],
    },
  ].filter(item => {
    // filter based on user permissions
    return user?.permissions?.some(permission =>
      item?.permissionName?.includes(permission),
    );
  });
  return (
    <Layout hasSider>
      <Sider
        width={230}
        style={{
          overflow: 'auto',
          height: '100vh',
          position: 'fixed',
          left: 0,
          top: 0,
          bottom: 0,
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          borderRight: '1px solid #f0f0f0',
        }}
      >
        <Link to='/'>
          <img src={Logo} alt='kraverich logo' className={css.logo} />
        </Link>
        <Menu className={css.menu}>
          {items.map((item, index) => {
            return (
              <Menu.Item
                onClick={() => {
                  item.onClick();
                }}
                icon={item.icon}
                key={index}
              >
                {item.label}
                {item.alerts ? (
                  <span className={css.alertsCountPill}>{item.alerts}</span>
                ) : (
                  <></>
                )}
              </Menu.Item>
            );
          })}
        </Menu>
      </Sider>
      <Layout className={css.childrenWrapper}>
        <ErrorBoundary>
          <Suspense
            fallback={
              <Flex
                justify='center'
                align='center'
                className={css.suspenseWrapper}
              >
                <Spin size='large' />
              </Flex>
            }
          >
            <Header />
            <Outlet />
          </Suspense>
        </ErrorBoundary>
      </Layout>
    </Layout>
  );
};

export default Dashboard;

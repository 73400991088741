export const ORDER_STATUS = {
  all: [
    'PLACED',
    'REJECTED',
    'REFUND_INIT',
    'REFUNDED',
    'ACCEPTED',
    'READY',
    'CANCELLED_BY_CUSTOMER',
    'CANCELLED_BY_ADMIN',
    'ON_HOLD',
    'FULFILLED',
    'PAYMENT_PENDING',
  ],
};

export const PERMISSIONS = {
  ADMIN: 'ADMIN',
  SUPERADMIN: 'SUPERADMIN',
  ORDERS: 'ORDERS',
  RESTAURANTS: 'RESTAURANTS',
  CUSTOMER: 'CUSTOMER',
  COUPONS: 'COUPONS',
  SUPPORT: 'SUPPORT',
  DELIVERY: 'DELIVERY',
  NUTRITIONIST: 'NUTRITIONIST',
};

export const DELIVERY_STATUSES = [
  'SEARCHING',
  'RTO_INIT',
  'RTO_COMPLETED',
  'CANCELLED_BY_ADMIN',
  'DRIVER_ASSIGNED',
  'REACHED_PICKUP',
  'SHIPPED',
  'DELIVERED',
  'RAIN',
  'RETURN',
  'COMPLETE',
  'CANCELLED',
  'RETURN_START',
  'DRIVER_REACHED_DROP',
  'DRIVER_ASSIGN_FAILED',
];

export const TIMESLOTS = {
  BREAKFAST: {
    timeSlots: [
      {
        label: '8:00 am - 8:30 am',
        value: 'BREAKFAST_0800AM_0830AM',
      },
      {
        label: '8:30 am - 9:00 am',
        value: 'BREAKFAST_0830AM_0900AM',
      },
      {
        label: '9:00 am - 9:30 am',
        value: 'BREAKFAST_0900AM_0930AM',
      },
      {
        label: '9:30 am - 10:00 am',
        value: 'BREAKFAST_0930AM_1000AM',
      },
      {
        label: '10:00 am - 10:30 am',
        value: 'BREAKFAST_1000AM_1030AM',
      },
    ],
  },
  LUNCH: {
    timeSlots: [
      {
        label: '1:00 pm - 1:30 pm',
        value: 'LUNCH_0100PM_0130PM',
      },
      {
        label: '1:30 pm - 2:00 pm',
        value: 'LUNCH_0130PM_0200PM',
      },
      {
        label: '2:00 pm - 2:30 pm',
        value: 'LUNCH_0200PM_0230PM',
      },
      {
        label: '2:30 pm - 3:00 pm',
        value: 'LUNCH_0230PM_0300PM',
      },
    ],
  },
  DINNER: {
    timeSlots: [
      {
        label: '8:00 pm - 8:30 pm',
        value: 'DINNER_0800PM_0830PM',
      },
      {
        label: '8:30 pm - 9:00 pm',
        value: 'DINNER_0830PM_0900PM',
      },
      {
        label: '9:00 pm - 9:30 pm',
        value: 'DINNER_0900PM_0930PM',
      },
      {
        label: '9:30 pm - 10:00 pm',
        value: 'DINNER_0930PM_1000PM',
      },
    ],
  },
};

export const WORKER_DELAY = 30000;

const GOALS = {
  WEIGHT_LOSS: {
    label: 'Weight Loss',
    value: 'WEIGHT_LOSS',
  },
  WEIGHT_GAIN: {
    label: 'Weight Gain',
    value: 'WEIGHT_GAIN',
  },
  DIGESTIVE_HEALTH: {
    label: 'Digestive Health',
    value: 'DIGESTIVE_HEALTH',
  },
  MUSCLE_GAIN: {
    label: 'Muscle Gain',
    value: 'MUSCLE_GAIN',
  },
  ATHLETIC_PERFORMANCE: {
    label: 'Athletic Performance',
    value: 'ATHLETIC_PERFORMANCE',
  },
  QUALITY_OF_LIFE: {
    label: 'Quality of life',
    value: 'QUALITY_OF_LIFE',
  },
};
const WEATHER = {
  SUMMER: {
    label: 'Summer',
    value: 'SUMMER',
  },
  WINTER: {
    label: 'Winter',
    value: 'WINTER',
  },
  RAINY: {
    label: 'Rainy',
    value: 'RAINY',
  },
};
const ALLERGENS = {
  GLUTEN: {
    label: 'Gluten',
    value: 'GLUTEN',
  },
  EGG: {
    label: 'Egg',
    value: 'EGG',
  },
  MILK: {
    label: 'Milk',
    value: 'MILK',
  },
  FISH: {
    label: 'Fish',
    value: 'FISH',
  },
  SOYBEANS: {
    label: 'Soybeans',
    value: 'SOYBEANS',
  },
  NUTS: {
    label: 'Nuts',
    value: 'NUTS',
  },
  SEAFOOD: {
    label: 'Seafood',
    value: 'SEAFOOD',
  },
  PORK: {
    label: 'Pork',
    value: 'PORK',
  },
  BEEF: {
    label: 'Beef',
    value: 'BEEF',
  },
  MUTTON: {
    label: 'Mutton',
    value: 'MUTTON',
  },
};
const TYPE = {
  BREAKFAST: {
    label: 'Breakfast',
    value: 'BREAKFAST',
  },
  LUNCH: {
    label: 'Lunch',
    value: 'LUNCH',
  },
  DINNER: {
    label: 'Dinner',
    value: 'DINNER',
  },
};
const PERSONA = {
  ATHLETE: {
    label: 'Athlete',
    value: 'ATHLETE',
  },
  CEO: {
    label: 'CEO',
    value: 'CEO',
  },
  ADVENTURER: {
    label: 'Adventurer',
    value: 'ADVENTURER',
  },
  GLAM_ENTHUSIAST: {
    label: 'Glam Enthusiast',
    value: 'GLAM_ENTHUSIAST',
  },
  BUSINESS_MAVERICK: {
    label: 'Business Maverick',
    value: 'BUSINESS_MAVERICK',
  },
  BODY_BUILDER: {
    label: 'Body Builder',
    value: 'BODY_BUILDER',
  },
  CONSCIOUS_FOODIE: {
    label: 'Conscious Foodie',
    value: 'CONSCIOUS_FOODIE',
  },
};

const CATEGORY = {
  ROUTINE: {
    label: 'Routine',
    value: 'ROUTINE',
  },
  SEASONAL: {
    label: 'Seasonal',
    value: 'SEASONAL',
  },
  PREMIUM: {
    label: 'Premium',
    value: 'PREMIUM',
  },
  CRAVING: {
    label: 'Craving',
    value: 'CRAVING',
  },
};

const KIND = {
  VEG: {
    label: 'Veg',
    value: 'VEG',
  },
  NON_VEG: {
    label: 'Non-Veg',
    value: 'NON_VEG',
  },
  EGG: {
    label: 'Egg',
    value: 'EGG',
  },
  VEGAN: {
    label: 'Vegan',
    value: 'VEGAN',
  },
};
// Item attribute id [1- Veg, 2- Non-Veg, 5- Other, 24- Egg]
const MEAL_ATTRIBUTE_ID = {
  VEG: {
    value: 1,
    label: 'Veg',
  },
  NON_VEG: {
    value: 2,
    label: 'Non-Veg',
  },
  OTHER: {
    value: 5,
    label: 'Other',
  },
  EGG: {
    value: 24,
    label: 'Egg',
  },
};

const MEAL_STATUS = {
  PENDING: {
    label: 'Pending',
    value: 'PENDING',
  },
  APPROVED: {
    label: 'Approved',
    value: 'APPROVED',
  },
  REJECTED: {
    label: 'Rejected',
    value: 'REJECTED',
  },
  ARCHIVED: {
    label: 'Archived',
    value: 'ARCHIVED',
  },
};

const NUTRITIONIST_STATUS = {
  APPROVED: 'APPROVED',
  APPROVAL_PENDING: 'APPROVAL_PENDING',
  REJECTED: 'REJECTED',
  BANNED: 'BANNED',
};

const USER_GOALS = {
  ANTI_AGING: {
    label: 'Anti Aging',
    value: 'ANTI_AGING',
  },
  ENDURANCE: {
    label: 'Endurance',
    value: 'ENDURANCE',
  },
  HAIR_HEALTH: {
    label: 'Hair Health',
    value: 'HAIR_HEALTH',
  },
  SKIN_HEALTH: {
    label: 'Skin Health',
    value: 'SKIN_HEALTH',
  },
  BONE_HEALTH: {
    label: 'Bone Health',
    value: 'BONE_HEALTH',
  },
  MUSCLE_GAIN: {
    label: 'Muscle Gain',
    value: 'MUSCLE_GAIN',
  },
  HEART_HEALTH: {
    label: 'Heart Health',
    value: 'HEART_HEALTH',
  },
  SLEEP_QUALITY: {
    label: 'Sleep Quality',
    value: 'SLEEP_QUALITY',
  },
  FAT_REDUCTION: {
    label: 'Fat Reduction',
    value: 'FAT_REDUCTION',
  },
  PEAK_ENERGY_AND_PERFORMANCE: {
    label: 'Peak Energy and Performance',
    value: 'PEAK_ENERGY_AND_PERFORMANCE',
  },
  SUSTAINED_MENTAL_STAMINA: {
    label: 'Sustained Mental Stamina',
    value: 'SUSTAINED_MENTAL_STAMINA',
  },
  POST_WORKOUT_RECOVERY: {
    label: 'Post Workout Recovery',
    value: 'POST_WORKOUT_RECOVERY',
  },
  FAST_MUSCLE_RECOVERY: {
    label: 'Fast Muscle Recovery',
    value: 'FAST_MUSCLE_RECOVERY',
  },
  STRESS_MANAGEMENT: {
    label: 'Stress Management',
    value: 'STRESS_MANAGEMENT',
  },
  INJURY_PREVENTION: {
    label: 'Injury Prevention',
    value: 'INJURY_PREVENTION',
  },
  IMMMUNE_SUPPORT: {
    label: 'Immune Support',
    value: 'IMMMUNE_SUPPORT',
  },
  STRENGTH_GAIN: {
    label: 'Strength Gain',
    value: 'STRENGTH_GAIN',
  },
  DETOXIFICATION: {
    label: 'Detoxification',
    value: 'DETOXIFICATION',
  },
};

const OPERATION_STATUS = {
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR',
};

export {
  OPERATION_STATUS,
  ALLERGENS,
  CATEGORY,
  GOALS,
  KIND,
  MEAL_ATTRIBUTE_ID,
  MEAL_STATUS,
  NUTRITIONIST_STATUS,
  PERSONA,
  TYPE,
  USER_GOALS,
  WEATHER,
};
